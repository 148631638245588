<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="setIsOpen(false)"
      :open="isOpen"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block
          sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              p-3
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8
              sm:align-middle
              sm:max-w-2xl
              sm:w-full
              sm:p-3
            "
          >
            <div class="text-center">
              <YoutubeVue3
                :videoid="selectedVideoId"
                :loop="false"
                :autoplay="true"
                width="100%"
              />
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                type="button"
                class="
                  inline-flex
                  justify-center
                  w-full
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-yellow-500
                  text-base
                  font-medium
                  text-white
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
                  sm:text-sm
                "
                @click="setIsOpen(false)"
              >
                Cerrar
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <div>
    <h1 class="font-zeyada text-4xl my-4">Vídeos</h1>

    <div
      class="
        w-12
        h-12
        mx-auto
        my-10
        border-2 border-yellow-500
        rounded-full
        loader
      "
      v-if="!isLoaded"
    ></div>

    <TransitionRoot
      :show="isLoaded"
      enter="transition-opacity duration-500"
      enter-from="opacity-0"
      enter-to="opacity-100"
    >
      <ul
        class="
          space-y-12
          sm:grid sm:grid-cols-2
          sm:gap-x-6 sm:gap-y-12
          sm:space-y-0
          lg:grid-cols-3
          lg:gap-x-8
        "
        v-if="isLoaded"
      >
        <li v-for="video in videos" :key="video.slug">
          <div class="cursor-pointer" @click="playVideo(video.video)">
            <div class="space-y-4">
              <div class="aspect-w-16 aspect-h-9">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  :src="getVideoThumb(video.video)"
                  :alt="video.titulo"
                />
              </div>

              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h3 class="text-center text-gray-600 text-sm">
                    {{ video.titulo }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";
import { YoutubeVue3 } from "youtube-vue3";
import { API_BASE_URL } from "@/config/parameters";

export default {
  name: "Videos",
  components: {
    Dialog,
    DialogOverlay,
    TransitionRoot,
    TransitionChild,
    YoutubeVue3,
  },
  setup() {
    const videos = ref([]);
    const isOpen = ref(false);
    const selectedVideoId = ref("");
    const isLoaded = ref(false);

    const extractVideoID = (url) => {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      if (match && match[7].length === 11) {
        return match[7];
      } else {
        return null;
      }
    };

    const playVideo = (url) => {
      selectedVideoId.value = extractVideoID(url);
      isOpen.value = true;
    };

    const getVideoThumb = (videoUrl) => {
      const videoId = extractVideoID(videoUrl);
      return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    };

    fetch(
      `${API_BASE_URL}/videos?_limit=1000&_start=0&_sort=updated_at:DESC&_publicationState=live`
    )
      .then((response) => response.json())
      .then((data) => {
        videos.value.push(...data);
        isLoaded.value = true;
      })
      .catch((error) => console.error(error));

    return {
      API_BASE_URL,
      videos,
      extractVideoID,
      getVideoThumb,
      isOpen,
      setIsOpen(value) {
        isOpen.value = value;
      },
      playVideo,
      selectedVideoId,
      isLoaded,
    };
  },
};
</script>
